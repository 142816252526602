import { useEffect } from "react";
import Spinner from "../images/spinner.svg";
import { PDFDocument, StandardFonts, rgb, PageSizes } from "pdf-lib";
import * as base64 from "byte-base64";
import download from "downloadjs";
import CONSTANTS from "../constants";
import format from "../Util/masker";
import stringSplitter from "../Util/stringSplit";
import { addUserDataToGoogleSheets } from "../Util/googleHelper";
import { goToNextPage, goToErrorPage } from "../navigator";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

function InformationLoading(props) {
    async function createPDF() {
        const reqUrl = "https://mybuckettestingemerald.s3.amazonaws.com/2019-ncov-test-requisition.pdf";
        const reqBytes = await fetch(reqUrl).then((res) => res.arrayBuffer());

        const pdfDoc = await PDFDocument.load(reqBytes);

        const reqForm = pdfDoc.getForm();

        reqForm.getTextField("1. Submitter - Clinician Surname, First Name").setText("Daharu, Tamara");
        reqForm.getTextField("1. Submitter - OHIP / CPSO / Prof. License Number").setText("OCP# 614951");
        reqForm.getTextField("1. Submitter -  Name of clinic / facility").setText("Emerald Pharmacy Remedy'sRx");
        reqForm.getTextField("1. Submitter - Address City Province Postal Code").setText("F-140 Hespeler Road, Cambridge, ON");
        reqForm.getTextField("1. Submitter - Postal Code").setText("N1R3H2");
        reqForm.getTextField("1. Submitter - Telephone number").setText("(519) 267-5030");
        reqForm.getTextField("1. Submitter - fax number").setText("(519) 267-5034");

        reqForm
            .getTextField("2. Patient - Health Card Number")
            .setText(format("XXXX-XXX-XXX", props.data.hc) + " " + props.data.versionCode);
        reqForm.getTextField("2. Patient - Last name per health card").setText(props.data.lastName);
        reqForm.getTextField("2. Patient - First name per health card").setText(props.data.firstName);
        reqForm.getTextField("2. Patient - Date of Birth").setText(props.data.dob);

        if (props.data.sex === "M") {
            reqForm.getRadioGroup("2. Patient Sex").select("Male");
        } else {
            reqForm.getRadioGroup("2. Patient Sex").select("Female");
        }

        reqForm
            .getTextField("2. Patient - Address")
            .setText(props.data.street + ", " + props.data.city + ", " + props.data.province);
        reqForm.getTextField("2. Patient - Postal Code").setText(props.data.postalCode);
        reqForm.getTextField("2. Patient Phone Number").setText(props.data.phone);
        reqForm.getTextField("2. Patient - Investigation / Outbreak No").setText(props.data.investigationNumber);

        // Travel
        if (props.data.travel) {
            // Pt did travel
            reqForm.getTextField("3. Travel History - Travel to").setText(props.data.travelDestination);
            reqForm.getTextField("3. Travel History - Date of Return").setText(props.data.travelReturnDate);
            // Return date is not required, so we do not fill it out
            // reqForm.getTextField("3. Travel History - Date of Travel").setText("");
        } else {
            // Pt did not travel
            reqForm.getTextField("3. Travel History - Travel to").setText("N/A");
        }

        // Exposure
        if (props.data.exposure) {
            // Pt was exposed
            reqForm.getRadioGroup("4. Exposure History - to probable or confirmed case").select("Yes");

            let exposureDetails = "";

            if (props.data.placeOfExposure !== "") {
                exposureDetails += "Place of exposure: " + props.data.placeOfExposure;
            }

            if (props.data.dateOfExposure !== "") {
                exposureDetails += " - Date of exposure: " + props.data.dateOfExposure;
            }

            if (props.data.exposureDetails !== "") {
                exposureDetails += " - Details: " + props.data.exposureDetails;
            }

            if (props.data.antigen) {
                exposureDetails += " - Positive rapid antigen test";
            }

            reqForm.getTextField("4. Exposure History - Exposure Details").setText(exposureDetails);
            reqForm
                .getTextField("4. Exposure History - Date of symptom onset of contact")
                .setText(props.data.dateOfContactSymptom);
        } else if (props.data.antigen) {
            reqForm.getTextField("4. Exposure History - Exposure Details").setText("Positive rapid antigen test");
            reqForm.getRadioGroup("4. Exposure History - to probable or confirmed case").select("Yes");
        } else {
            // Pt not exposed
            if (props.data.antigen) {
                reqForm.getTextField("4. Exposure History - Exposure Details").setText("Positive rapid antigen test");
                reqForm.getRadioGroup("4. Exposure History - to probable or confirmed case").select("Yes");
            } else {
                reqForm.getTextField("4. Exposure History - Exposure Details").setText("N/A");
                reqForm.getRadioGroup("4. Exposure History - to probable or confirmed case").select("No");
            }
        }

        if (props.data.reasonForTest !== "") {
            reqForm.getTextField("4. Exposure History - Exposure Details").setText(props.data.reasonForTest);
        }

        // Tests requested
        reqForm.getRadioGroup("5. Test(s) Requested").select("COVID-19 Virus");

        // Specimen type
        reqForm.getCheckBox("6. Specimens collect each - Oral (Buccal) + Deep Nasal").check();
        reqForm.getTextField("6. Specimens Collect Each - Collection Date").setText(props.data.dateOfTest);

        // Patient setting/type
        reqForm.getCheckBox("7. Patient Setting - Assessment Centre").check();

        if (props.data.groups[0] !== CONSTANTS.GROUPS_NONE) {
            let healthcare = false;
            let remote = false;
            let homeless = false;

            const filteredGroups = props.data.groups.filter((group) => {
                if (group === CONSTANTS.GROUPS_SYMPTOMATIC_HEALTHCARE) {
                    healthcare = true;
                    return false;
                } else if (group === CONSTANTS.GROUPS_ASYMPTOMATIC_INDIGENOUS || group === CONSTANTS.GROUPS_ASYMPTOMATIC_INDIGENOUS) {
                    remote = true;
                    return false;
                } else if (group === CONSTANTS.GROUPS_SYMPTOMATIC_HOMELESS) {
                    homeless = true;
                    return false;
                } else {
                    return true;
                }
            });

            if (healthcare) {
                reqForm.getCheckBox("7. Patient Setting - Healthcare worker").check();
            }

            if (remote) {
                reqForm.getCheckBox("7. Patient Setting - Remote Community").check();
            }

            if (homeless) {
                reqForm.getCheckBox("7. Patient Setting - Unhoused/Shelter").check();
            }

            if (props.data.ltcHomeName.length > 0) {
                reqForm.getCheckBox("7. Patient Setting - Institution / all group settings").check();
                reqForm.getTextField("7. Patient Setting - Home ID number").setText(props.data.ltcHomeName);
            }

            if (filteredGroups.length > 0) {
                reqForm.getCheckBox("7. Patient Setting - Other Specify").check();
                reqForm.getTextField("7. Patient Setting - Other Specify Notes").setText(filteredGroups.join(", "));
            }
        }

        // Immunization
        // Options:
        // "Received all required doses >14 days ago"
        // "Unimmunized "
        // "Unknown"
        if (props.data.vaccination === CONSTANTS.VACCINATION_TWO_OVER_FOURTEEN_DAYS) {
            reqForm.getRadioGroup("COVID-19 Vaccination Status").select("Received all required doses >14 days ago");
        } else {
            reqForm.getRadioGroup("COVID-19 Vaccination Status").select("Unimmunized ");
        }

        // Clinical information
        if (props.data.pregnant) {
            reqForm.getCheckBox("9. Clinical Information - Pregnant").check();
        }

        if (props.data.symptoms[0] === CONSTANTS.SYMPTOMS_ASYMPTOMATIC) {
            // Asymptomatic
            reqForm.getCheckBox("9. Clinical information - Asymptomatic").check();
        } else {
            // Symptomatic
            reqForm.getCheckBox("9. Clinical Information - Symptomatic").check();

            let cough = false;
            let soreThroat = false;
            let fever = false;

            const filteredSymptoms = props.data.symptoms.filter((sx) => {
                if (sx === CONSTANTS.SYMPTOMS_COUGH) {
                    cough = true;
                    return false;
                } else if (sx === CONSTANTS.SYMPTOMS_SORE_THROAT) {
                    soreThroat = true;
                    return false;
                } else if (sx === CONSTANTS.SYMPTOMS_FEVER) {
                    fever = true;
                    return false;
                } else {
                    return true;
                }
            });

            if (cough) {
                reqForm.getCheckBox("9. Clinical Information - Cough").check();
            }

            if (soreThroat) {
                reqForm.getCheckBox("9. Clinical Information - Sore Throat").check();
            }

            if (fever) {
                reqForm.getCheckBox("9. Clinical Information - Fever").check();
            }

            if (filteredSymptoms.length > 0) {
                // Has other symptoms
                reqForm.getCheckBox("9. Clinical Information - Other").check();
                reqForm.getTextField("9. Clinical Information - Other Specify").setText(filteredSymptoms.join(", "));
            }

            reqForm.getTextField("9. Clinical Information - Date of symptom onset").setText(props.data.symptomsOnsetDate);
        }

        const reqPdfBytes = await pdfDoc.save();
        // download(reqPdfBytes, "req", "application/pdf");
        const reqAttachment = base64.bytesToBase64(reqPdfBytes);

        // Create label
        const labelDoc = await PDFDocument.create();
        const helveticaLabel = await labelDoc.embedFont(StandardFonts.Helvetica);

        const labelPage = labelDoc.addPage([349, 74]);
        const { height } = labelPage.getSize();
        const fontSize = 12;
        labelPage.drawText(`${props.data.lastName}, ${props.data.firstName}`, {
            x: 15,
            y: height - 20,
            size: fontSize,
            font: helveticaLabel,
            color: rgb(0, 0, 0),
        });

        labelPage.drawText(`DOB: ${props.data.dob} (YYYY/MM/DD)`, {
            x: 15,
            y: height - 35,
            size: fontSize,
            font: helveticaLabel,
            color: rgb(0, 0, 0),
        });

        labelPage.drawText(`HC#: ${format("XXXX-XXX-XXX", props.data.hc)} ${props.data.versionCode}`, {
            x: 15,
            y: height - 50,
            size: fontSize,
            font: helveticaLabel,
            color: rgb(0, 0, 0),
        });

        labelPage.drawText(`Date collected: ${props.data.dateOfTest}`, {
            x: 15,
            y: height - 65,
            size: fontSize,
            font: helveticaLabel,
            color: rgb(0, 0, 0),
        });

        const labelBytes = await labelDoc.save();
        const labelAttachment = base64.bytesToBase64(labelBytes);

        // Create screening
        const screeningDoc = await PDFDocument.create();
        const helveticaScreening = await screeningDoc.embedFont(StandardFonts.Helvetica);
        const fontSizeData = 10;

        const screeningPage = screeningDoc.addPage(PageSizes.Letter);

        screeningPage.moveTo(15, screeningPage.getSize().height - 30);

        screeningPage.drawText(`COVID-19 PCR Screening for ${props.data.lastName}, ${props.data.firstName}`, {
            size: 18,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(25);

        // Demographics
        screeningPage.drawText(`Demographics`, {
            size: 15,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`DOB: ${props.data.dob} (YYYY/MM/DD)`, {
            size: fontSizeData,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`HC#: ${props.data.hc} ${props.data.versionCode}`, {
            size: fontSizeData,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Sex: ${props.data.sex}`, {
            size: fontSizeData,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Phone #: ${props.data.phone}`, {
            size: fontSizeData,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Email: ${props.data.email}`, {
            size: fontSizeData,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(
            `Address: ${props.data.street}, ${props.data.city}, ${props.data.province}, ${props.data.postalCode}`,
            {
                size: fontSizeData,
                font: helveticaScreening,
                color: rgb(0, 0, 0),
            }
        );

        screeningPage.moveDown(25);

        // Vaccination status
        screeningPage.drawText(`Vaccination`, {
            size: 15,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Vaccination: ${props.data.vaccination}`, {
            size: fontSizeData,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(25);

        // Vaccination status
        screeningPage.drawText(`Symptoms`, {
            size: 15,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        const symptomsCommaSeperated = props.data.symptoms.join(", ");
        const symptomsSplit = stringSplitter(symptomsCommaSeperated, 115);

        if (symptomsSplit.length > 1) {
            symptomsSplit.forEach((line, index) => {
                if (index === 0) {
                    screeningPage.drawText(`Symptoms: ${line}-`, {
                        size: fontSizeData,
                        font: helveticaScreening,
                        color: rgb(0, 0, 0),
                    });
                } else if (index === symptomsSplit.length - 1) {
                    screeningPage.drawText(`${line}`, {
                        size: fontSizeData,
                        font: helveticaScreening,
                        color: rgb(0, 0, 0),
                    });
                } else {
                    screeningPage.drawText(`${line}-`, {
                        size: fontSizeData,
                        font: helveticaScreening,
                        color: rgb(0, 0, 0),
                    });
                }

                screeningPage.moveDown(15);
            });
        } else {
            screeningPage.drawText(`Symptoms: ${symptomsCommaSeperated}`, {
                size: fontSizeData,
                font: helveticaScreening,
                color: rgb(0, 0, 0),
            });

            screeningPage.moveDown(15);
        }

        screeningPage.drawText(`Date of symptom onset: ${props.data.symptomsOnsetDate}`, {
            size: fontSizeData,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(25);

        // Exposure
        screeningPage.drawText(`Exposure`, {
            size: 15,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Has had exposure: ${props.data.exposure}`, {
            size: fontSizeData,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Place of exposure: ${props.data.placeOfExposure}`, {
            size: fontSizeData,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Date of exposure: ${props.data.dateOfExposure}`, {
            size: fontSizeData,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        if (props.data.exposureDetails === "") {
            screeningPage.drawText(`Exposure details:`, {
                size: fontSizeData,
                font: helveticaScreening,
                color: rgb(0, 0, 0),
            });

            screeningPage.moveDown(15);
        } else {
            const exposureSplit = stringSplitter(props.data.exposureDetails, 115);

            if (exposureSplit.length > 1) {
                exposureSplit.forEach((line, index) => {
                    if (index === 0) {
                        screeningPage.drawText(`Exposure details: ${line}-`, {
                            size: fontSizeData,
                            font: helveticaScreening,
                            color: rgb(0, 0, 0),
                        });
                    } else if (index === exposureSplit.length - 1) {
                        screeningPage.drawText(`${line}`, {
                            size: fontSizeData,
                            font: helveticaScreening,
                            color: rgb(0, 0, 0),
                        });
                    } else {
                        screeningPage.drawText(`${line}-`, {
                            size: fontSizeData,
                            font: helveticaScreening,
                            color: rgb(0, 0, 0),
                        });
                    }

                    screeningPage.moveDown(15);
                });
            } else {
                screeningPage.drawText(`Exposure details: ${props.data.exposureDetails}`, {
                    size: fontSizeData,
                    font: helveticaScreening,
                    color: rgb(0, 0, 0),
                });

                screeningPage.moveDown(15);
            }
        }

        screeningPage.drawText(`Investigation #: ${props.data.investigationNumber}`, {
            size: fontSizeData,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Date of symptoms onset of contact: ${props.data.dateOfContactSymptom}`, {
            size: fontSizeData,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(25);

        // Groups
        screeningPage.drawText(`Groups`, {
            size: 15,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Groups: ${props.data.groups.join(", ")}`, {
            size: fontSizeData,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Facility name: ${props.data.ltcHomeName}`, {
            size: fontSizeData,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(25);

        // Pregnancy
        screeningPage.drawText(`Pregnacy`, {
            size: 15,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Pregnant: ${props.data.pregnant}`, {
            size: fontSizeData,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(25);

        // Appointment
        screeningPage.drawText(`Appointment information`, {
            size: 15,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(
            `Appointment date: ${dayjs(props.data.dateOfTest, "YYYY/MM/DD").format("dddd MMMM D, YYYY")}`, // Wednesday December 6, 2021
            {
                size: fontSizeData,
                font: helveticaScreening,
                color: rgb(0, 0, 0),
            }
        );

        screeningPage.moveDown(15);

        screeningPage.drawText(`Appointment time: ${props.data.timeOfTest}`, {
            size: fontSizeData,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(25);

        // Metadata
        screeningPage.drawText(`Metadata`, {
            size: 15,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        const dateSubmitted = dayjs().format("YYYY/MM/DD");
        const timeSubmitted = dayjs().format("h:mm A");

        screeningPage.drawText(`Date submitted: ${dateSubmitted} (YYYY/MM/DD)`, {
            size: fontSizeData,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Time submitted: ${timeSubmitted}`, {
            size: fontSizeData,
            font: helveticaScreening,
            color: rgb(0, 0, 0),
        });

        const screeningBytes = await screeningDoc.save();
        // download(screeningBytes, "screening.pdf", "application/pdf");
        const screeningAttachment = base64.bytesToBase64(screeningBytes);

        const msg = {
            to: "emeraldpharmacyrx@gmail.com",
            from: "no-reply@cloudmedical.ca",
            subject: `COVID-19 Testing Submission from ${props.data.firstName} ${props.data.lastName}`,
            attachments: [
                {
                    filename: `Requsition ${props.data.firstName} ${props.data.lastName}.pdf`,
                    content: reqAttachment,
                    encoding: "base64",
                },
                {
                    filename: `Label ${props.data.firstName} ${props.data.lastName}.pdf`,
                    content: labelAttachment,
                    encoding: "base64",
                },
                {
                    filename: `Screening ${props.data.firstName} ${props.data.lastName}.pdf`,
                    content: screeningAttachment,
                    encoding: "base64",
                },
            ],
            text: `A patient has just registered for the COVID-19 symptomatic testing. Their information is below: \n\nName: ${
                props.data.lastName
            }, ${props.data.firstName}\nHealthcard #: ${props.data.hc}${props.data.versionCode}\nDate of birth: ${dayjs(
                props.data.dob,
                "YYYY/MM/DD"
            ).format("DD/MM/YYYY")}\nSex: ${props.data.sex}\nAddress: ${props.data.street}, ${props.data.city}, ${
                props.data.province
            }, ${props.data.postalCode}\nPhone number: ${props.data.phone}\nEmail address: ${
                props.data.email
            }\nAppointment date: ${dayjs(props.data.dateOfTest, "YYYY/MM/DD").format("dddd MMMM D, YYYY")}\nAppointment time: ${
                props.data.timeOfTest
            }`,
        };

        const wrapper = {
            message: msg,
        };

        const ptDob = dayjs(props.data.dob, "YYYY/MM/DD").format("YYYYMMDD");
        const currentDate = dayjs().format("YYYYMMDD");
        const age = Math.floor((currentDate - ptDob) * 0.0001);

        let sHc = props.data.hc;

        if (age > 24 && age < 65) {
            sHc = ("S" + sHc);
        }

        fetch("/api/sendEmail", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(wrapper),
        })
            .then((res) => res.json())
            .then((data) => {
                // enter you logic when the fetch is successful

                addUserDataToGoogleSheets(
                    props.data.lastName + "," + props.data.firstName,
                    dayjs(props.data.dob, "YYYY/MM/DD").format("DDMMYYYY"),
                    props.data.sex === "M" ? "Male" : "Female",
                    sHc,
                    dayjs(props.data.dateOfTest, "YYYY/MM/DD").format("dddd MMMM D, YYYY"),
                    props.data.timeOfTest,
                    dateSubmitted,
                    timeSubmitted
                )
                    .then((data) => {
                        goToNextPage(props, CONSTANTS.PAGE_INFORMATION_LOADING);
                    })
                    .catch((e) => {
                        // Error occurred with submitting data but we still want to move forward
                        goToNextPage(props, CONSTANTS.PAGE_INFORMATION_LOADING);
                    });
            })
            .catch((error) => {
                // enter your logic for when there is an error (ex. error toast)
                // console.log(error);
                goToErrorPage(props);
            });
    }

    async function createAppointment() {
        const bookedFrom = dayjs(props.data.dateOfTest + " " + props.data.timeOfTest, "YYYY/MM/DD h:mm A");
        const bookedTo = bookedFrom.add(5, "minute");

        const apptBookResponse = await fetch("/api/bookAppt", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                bookedFrom: bookedFrom.format("YYYY-MM-DD HH:mm"),
                bookedTo: bookedTo.format("YYYY-MM-DD HH:mm"),
                title: props.data.firstName + " " + props.data.lastName,
            }),
        });

        const apptBookResponseJson = await apptBookResponse.json();

        if (!apptBookResponse.ok) {
            console.log(apptBookResponseJson);
        } else {
            console.log(apptBookResponseJson.message);
        }
    }

    async function sendConfirmationEmail() {
        const apptString =
            dayjs(props.data.dateOfTest, "YYYY/MM/DD").format("dddd MMMM D, YYYY") + " at " + props.data.timeOfTest;

        const confirmationEmailResponse = await fetch("/api/sendConfirmation", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ptEmail: props.data.email,
                firstName: props.data.firstName,
                ptApptTime: apptString,
            }),
        });

        const confirmationEmailResponseJson = await confirmationEmailResponse.json();

        if (!confirmationEmailResponse.ok) {
            console.log(confirmationEmailResponseJson);
        } else {
            console.log(confirmationEmailResponseJson);
        }
    }

    useEffect(() => {
        dayjs.extend(customParseFormat);

        createPDF();
        createAppointment();
        sendConfirmationEmail();
    }, []); // <-- Have to pass in [] here!

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Loading</span>
            </h2>
            <p className="mt-4 text-lg leading-6 text-white">We are submitting your information.</p>
            <img className="animate-spin mt-8 h-10 w-10 text-blue-500" src={Spinner}></img>
        </>
    );
}

export default InformationLoading;
