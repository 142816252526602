import { useEffect } from "react";
import Spinner from "../images/spinner.svg";
import { goToNextPage, goToErrorPage } from "../navigator";
import CONSTANTS from "../constants";
import dayjs from "dayjs";

function WaitlistLoading(props) {
    async function submitEmailToWaitlist() {
        const dateSubmitted = dayjs().format("YYYY/MM/DD");
        const timeSubmitted = dayjs().format("h:mm A");

        const submitWaitlistResponse = await fetch("/api/addEmailToWaitlist", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: props.data.email,
                date: dateSubmitted,
                time: timeSubmitted,
            }),
        });

        if (!submitWaitlistResponse.ok) {
            goToErrorPage(props);
        } else {
            goToNextPage(props, CONSTANTS.PAGE_WAITLIST_LOADING);
        }
    }

    useEffect(() => {
        submitEmailToWaitlist();
    }, []);

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Loading</span>
            </h2>
            <p className="mt-4 text-lg leading-6 text-white">We are submitting your information.</p>
            <img className="animate-spin mt-8 h-10 w-10 text-blue-500" src={Spinner}></img>
        </>
    );
}

export default WaitlistLoading;
