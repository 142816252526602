import { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import { goToNextPage, goToPreviousPage } from "../navigator";
import CONSTANTS from "../constants";
import { ArrowLeftIcon } from "@heroicons/react/solid";

const selections = [
    {
        name: CONSTANTS.VACCINATION_TWO_OVER_FOURTEEN_DAYS,
    },
    {
        name: CONSTANTS.VACCINATION_TWO_LESS_FOURTEEN_DAYS,
    },
    {
        name: CONSTANTS.VACCINATION_ONE_VACCINE,
    },
    {
        name: CONSTANTS.VACCINATION_UNVACCINATED,
    },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function Vaccination(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [selected, setSelected] = useState(selections[-1]);
    const [isError, setIsError] = useState(false);

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">What is your COVID-19 vaccination status?</span>
            </h2>
            <p className="my-4 text-lg leading-6 text-white">
                This is mandatory for information purposes only and does not affect testing eligibility.
            </p>
            <RadioGroup
                className="md:max-w-lg"
                value={selected}
                onChange={setSelected}
                onClick={() => {
                    setIsError(false);
                }}
            >
                <div className="bg-white rounded-md">
                    {selections.map((selection, selectionIdx) => (
                        <RadioGroup.Option
                            key={selection.name}
                            value={selection}
                            className={({ checked }) =>
                                classNames(
                                    selectionIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                                    selectionIdx === selections.length - 1 ? "rounded-bl-md rounded-br-md" : "",
                                    checked ? "bg-blue-50 border-blue-200 z-10" : "border-gray-200",
                                    "relative border p-4 flex cursor-pointer focus:outline-none"
                                )
                            }
                        >
                            {({ checked }) => (
                                <>
                                    <span
                                        className={classNames(
                                            checked ? "bg-blue-500 border-transparent" : "bg-white border-gray-300",
                                            "h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center flex-shrink-0"
                                        )}
                                    >
                                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                                    </span>
                                    <div className="ml-3 flex flex-col">
                                        <RadioGroup.Label
                                            as="span"
                                            className={classNames(
                                                checked ? "text-blue-900" : "text-gray-900",
                                                "block text-sm font-medium"
                                            )}
                                        >
                                            {selection.name}
                                        </RadioGroup.Label>
                                    </div>
                                </>
                            )}
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
            <p className={classNames(!isError && "invisible", "mt-1 text-sm text-red-500")}>A selection must be made</p>

            <div className="flex justify-end sm:justify-start">
                <button
                    onClick={() => {
                        if (selected === undefined) {
                            setIsError(true);
                        } else {
                            props.setters.setVaccination(selected.name);
                            goToNextPage(props, CONSTANTS.PAGE_VACCINATION);
                        }
                    }}
                    className="mt-6 bg-white white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-800 hover:bg-gray-200"
                >
                    Next
                </button>
            </div>
        </>
    );
}

export default Vaccination;
