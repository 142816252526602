import { useState, useEffect } from "react";
import { goToNextPage, goToPreviousPage } from "../navigator";
import CONSTANTS from "../constants";
import { ChevronRightIcon, ChevronLeftIcon, ArrowLeftIcon } from "@heroicons/react/solid";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function VisitDateTime(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const [selectedDay, setSelectedDay] = useState(0);
    const [selectedDateOfTest, setSelectedDateOfTest] = useState("");
    const [selectedTimeOfTest, setSelectedTimeOfTest] = useState("");
    const [nextChevronActive, setNextChevronActive] = useState(true);
    const [previousChevronActive, setPreviousChevronActive] = useState(false);

    const [selectedError, setSelectedError] = useState(false);

    const nextChevronClickHandler = () => {
        let newSelectedDay;

        if (selectedDay < props.data.availableDates.length - 1) {
            newSelectedDay = selectedDay + 1;
            setPreviousChevronActive(true);
        } else {
            newSelectedDay = selectedDay;
        }

        // Set next chevron to disabled if on the last item
        if (newSelectedDay + 1 > props.data.availableDates.length - 1) {
            setNextChevronActive(false);
        } else {
            setNextChevronActive(true);
        }

        setSelectedDay(newSelectedDay);
    };

    const previousChevronClickHandler = () => {
        let newSelectedDay;

        if (selectedDay > 0) {
            newSelectedDay = selectedDay - 1;
            setNextChevronActive(true);
        } else {
            newSelectedDay = selectedDay;
        }

        // Set previous chevron to disabled if on the last item
        if (newSelectedDay - 1 < 0) {
            setPreviousChevronActive(false);
        } else {
            setPreviousChevronActive(true);
        }

        setSelectedDay(newSelectedDay);
    };

    const timeClickHandler = (dateOfTest, timeOfTest) => {
        setSelectedError(false);
        setSelectedDateOfTest(dateOfTest);
        setSelectedTimeOfTest(timeOfTest);
    };

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Choose a time for testing</span>
            </h2>
            <p className="my-4 text-lg leading-6 text-white">
                Please do not arrive more than 5 minutes prior to your appointment.
            </p>

            <div className="bg-white rounded-md p-4">
                <div className="text-gray-900 text-lg flex items-center justify-between mb-4">
                    {props.data.availableDates.length > 0 ? (
                        <h3 className="text-lg text-gray-900 flex ">{props.data.availableDates[selectedDay].dateString}</h3>
                    ) : (
                        <h3 className="text-lg text-gray-900 flex ">No appointments available</h3>
                    )}
                    <div className="flex">
                        <button
                            onClick={() => {
                                previousChevronClickHandler();
                            }}
                            className={
                                previousChevronActive
                                    ? "flex items-center justify-center mx-1 p-1 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-200"
                                    : "cursor-not-allowed flex items-center justify-center mx-1 p-1 rounded-md border border-gray-300 bg-gray-300 text-sm font-medium text-gray-500"
                            }
                        >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
                        </button>
                        <button
                            onClick={() => {
                                nextChevronClickHandler();
                            }}
                            className={
                                nextChevronActive
                                    ? "flex items-center justify-center mx-1 p-1 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-200"
                                    : "cursor-not-allowed flex items-center justify-center mx-1 p-1 rounded-md border border-gray-300 bg-gray-300 text-sm font-medium text-gray-500"
                            }
                        >
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon className="h-8 w-8" aria-hidden="true" />
                        </button>
                    </div>
                </div>

                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4  ">
                    {props.data.availableSlots
                        .filter((slot) => {
                            return props.data.availableDates[selectedDay].date === slot.date;
                        })
                        .map((slot) => (
                            <button
                                key={slot.date}
                                onClick={() => {
                                    timeClickHandler(slot.date, slot.time);
                                }}
                                className={
                                    slot.date === selectedDateOfTest && slot.time === selectedTimeOfTest
                                        ? "text-white rounded-lg border border-gray-300 bg-blue-500 py-4 justify-center shadow-sm flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                                        : "text-gray-900 rounded-lg border border-gray-300 bg-white py-4 justify-center shadow-sm flex items-center space-x-3 hover:bg-gray-200 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                                }
                            >
                                <div className="flex focus:outline-none">
                                    <p className="text-sm font-medium ">{slot.time}</p>
                                </div>
                            </button>
                        ))}
                </div>
            </div>
            <p className={classNames(!selectedError && "hidden", "mt-1 text-sm text-red-500")}>A selection must be made</p>
            {selectedTimeOfTest !== "" && selectedDateOfTest !== "" && (
                <div className="mt-8 md:mt-4">
                    <div className="text-md leading-6 text-white">
                        Your appointment is on:
                        <span className="font-bold">
                            {" "}
                            {dayjs(selectedDateOfTest, "YYYY/MM/DD").format("dddd MMMM D, YYYY")} at {selectedTimeOfTest}
                        </span>
                    </div>
                </div>
            )}

            <div className="flex justify-end sm:justify-start">
                <button
                    onClick={() => {
                        if (selectedDateOfTest === "" || selectedTimeOfTest === "") {
                            setSelectedError(true);
                        } else {
                            props.setters.setTimeOfTest(selectedTimeOfTest);
                            props.setters.setDateOfTest(selectedDateOfTest);
                            goToNextPage(props, CONSTANTS.PAGE_VISIT_DATE_TIME);
                        }
                    }}
                    className="mt-8 bg-white white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-800 hover:bg-gray-200"
                >
                    Next
                </button>
            </div>
        </>
    );
}

export default VisitDateTime;
