import CONSTANTS from "../constants";
import { useState, useEffect } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import validateEmail from "../Util/emailValidator";
import { goToNextPage } from "../navigator";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function NoAppointments(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);

    const emailChangeHandler = (value) => {
        setEmailError(false);
        setEmail(value);
    };

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">No appointments available</span>
            </h2>
            <p className="mt-2 text-lg leading-6 text-white">
                Unfortunately, no appointments are available at this time. We are regularly opening appointment times for same-day
                and next day. Please try again later.
            </p>
            <h2 className="text-xl font-extrabold text-white sm:text-xl mt-8">
                <span className="block underline">Register for waitlist</span>
            </h2>
            <p className="mt-2 text-lg leading-6 text-white">
                Enter your email address below to be notified when new appointments are available.
            </p>
            <div className="md:max-w-md">
                <div className="mt-4 relative">
                    <input
                        type="email"
                        name="waitlist"
                        id="waitlist"
                        placeholder="Email address"
                        inputMode="email"
                        value={email}
                        onChange={(e) => {
                            emailChangeHandler(e.target.value);
                        }}
                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block sm:text-sm border-gray-300 rounded-md w-full"
                    />
                    <div
                        className={classNames(
                            !emailError && "invisible",
                            "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                        )}
                    >
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                    </div>
                </div>
                <p className={classNames(!emailError && "hidden", "mt-1 text-sm text-red-500")}>
                    Please enter a valid email address.
                </p>
            </div>

            <div className="flex">
                <button
                    onClick={() => {
                        if (!validateEmail(email.toLowerCase().trim())) {
                            setEmailError(true);
                        } else {
                            // Email is valid
                            props.setters.setEmail(email.toLowerCase().trim());
                            goToNextPage(props, CONSTANTS.PAGE_NO_APPOINTMENTS);
                        }
                    }}
                    className="mt-8 bg-white white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-800 hover:bg-gray-200"
                >
                    Register
                </button>

                <button
                    onClick={() => {
                        props.reset();
                        props.setters.setStep(CONSTANTS.PAGE_INTRO);
                    }}
                    className="ml-4 mt-8 bg-white white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-800 hover:bg-gray-200"
                >
                    Try again
                </button>
            </div>
        </>
    );
}

export default NoAppointments;
