import { useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import format from "../Util/masker";
import { goToNextPage, goToPreviousPage } from "../navigator";
import CONSTANTS from "../constants";
import { ArrowLeftIcon } from "@heroicons/react/solid";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function InformationConfirmation(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const informationList = [
        {
            title: "First Name",
            info: props.data.firstName,
        },
        {
            title: "Last Name",
            info: props.data.lastName,
        },
        {
            title: "Healthcard Number",
            info: `${format("XXXX-XXX-XXX", props.data.hc)} ${props.data.versionCode}`,
        },
        {
            title: "Date of Birth (yyyy/mm/dd)",
            info: props.data.dob,
        },
        {
            title: "Sex",
            info: props.data.sex === "M" ? "Male" : "Female",
        },
        {
            title: "Email address",
            info: props.data.email,
        },
        {
            title: "Address",
            info: `${props.data.street}, ${props.data.city}, ${props.data.province}, ${props.data.postalCode}`,
        },
    ];

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Please confirm the information you have entered</span>
            </h2>
            <p className="my-4 text-lg leading-6 text-white">
                Please ensure the information that you have entered matches your healthcard.
            </p>
            <RadioGroup className="max-w-lg">
                <div className="bg-white rounded-md">
                    <div className=" sm:p-0">
                        <dl className="sm:divide-y sm:divide-gray-200">
                            {informationList.map((infoItem, index) => {
                                return (
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4 px-4" key={index}>
                                        <dt className="text-sm font-medium text-gray-500 sm:col-span-1">{infoItem.title}</dt>
                                        <dd className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-1 break-words">
                                            {infoItem.info}
                                        </dd>
                                    </div>
                                );
                            })}
                        </dl>
                    </div>
                </div>
            </RadioGroup>
            <div className="flex justify-between sm:justify-start">
                <button
                    onClick={() => {
                        goToPreviousPage(props, CONSTANTS.PAGE_INFORMATION_CONFIRMATION);
                    }}
                    className="sm:mr-14 mt-8 bg-transparent white border border-transparent rounded-md shadow py-3 inline-flex items-center text-base font-medium text-gray-400 hover:text-gray-200"
                >
                    <ArrowLeftIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                    Go back
                </button>
                <button
                    onClick={() => {
                        goToNextPage(props, CONSTANTS.PAGE_INFORMATION_CONFIRMATION);
                    }}
                    className="mt-8 bg-white white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-800 hover:bg-gray-200"
                >
                    Next
                </button>
            </div>
        </>
    );
}

export default InformationConfirmation;
