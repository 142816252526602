/**
 * 
 * @param {String} myString 
 * @param {Int} chunkSize 
 * @returns Array of string split
 */
function stringSplitter(myString, chunkSize) {
    let splitString = [];
    for (let i = 0; i < myString.length; i = i + chunkSize) {
        splitString.push(myString.slice(i, i + chunkSize));
    }
    return splitString;
}

export default stringSplitter;