import CONSTANTS from "../constants";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useEffect } from "react";

function Ineligible(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    dayjs.extend(customParseFormat);

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">This submission does not meet the eligibility criteria</span>
            </h2>

            <h2 className="text-xl font-extrabold text-red-500 sm:text-xl mt-8">
                <span className="block underline">New PCR Testing Guidelines (effective Dec 31, 2021)</span>
            </h2>
            <p className="mt-2 text-lg leading-6 text-white">
                Unfortunately, the Ministry of Health has updated PCR testing criteria. At this time you are ineligible. Please
                click the button below to review new eligibility criteria.
            </p>

            <p className="mt-6 text-lg leading-6 text-white">
                We understand that this is frustrating and unfortunately we are required to comply with this change. If you have
                any concerns regarding this change, please contact your MPP.
            </p>

            <p className="mt-6 text-lg leading-6 text-white">
                Media articles regarding this change can be found at the following websites:{" "}
                <a className="text-blue-500 underline" href="https://www.cbc.ca/news/canada/toronto/ontario-testing-isolation-guidance-1.6300831" target={"_blank"}>
                    CBC
                </a>{" "}
                and{" "}
                <a href="https://toronto.ctvnews.ca/this-is-who-can-and-can-t-get-a-covid-19-test-in-ontario-1.5723794" target={"_blank"} className="text-blue-500 underline">
                    CTV
                </a>
                .
            </p>
            
            <h2 className="text-xl font-extrabold text-red-500 sm:text-xl mt-8">
                <span className="block underline">Important note</span>
            </h2>
            <p className="mt-2 text-lg leading-6 text-white">
                Do not resubmit the form with false information. Multiple submissions will be considered invalid and will result in the cancellation of the appointment.
            </p>

            <div className="flex">
                <a href="https://covid-19.ontario.ca/exposed" target={"_blank"}>
                    <button className="mt-8 bg-white white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-800 hover:bg-gray-200">
                        View eligibility
                    </button>
                </a>
            </div>
        </>
    );
}

export default Ineligible;