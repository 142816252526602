import { useEffect } from "react";
import CONSTANTS from "../constants";

function Error(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Oops, we hit a snag</span>
            </h2>
            <p className="mt-4 text-lg leading-6 text-white">
                Unfortunately, there was an error. Please call us at 519-267-5030 or try again.
            </p>
            <div className="flex">
                <a href="tel:+15192675030">
                    <button
                        className="mt-8 bg-white white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-800 hover:bg-gray-200"
                    >
                        Call us
                    </button>
                </a>

                <button
                    onClick={() => {
                        props.setters.setStep(CONSTANTS.PAGE_INTRO);
                    }}
                    className="ml-4 mt-8 bg-white white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-800 hover:bg-gray-200"
                >
                    Try again
                </button>
            </div>
        </>
    );
}

export default Error;
