import CONSTANTS from "./constants";

function shouldPregnancyShow(sex, dob) {
    const yearOfBirth = dob.split("/")[0];
    const currentYear = new Date().getFullYear();
    const age = currentYear - yearOfBirth;

    if (sex === "F" && age >= 11 && age <= 50) {
        return true;
    } else {
        return false;
    }
}

export function goToNextPage(props, currentPage, antigen, hasAppt, isAsymptomatic, exposed, noGroup) {
    switch (currentPage) {
        case CONSTANTS.PAGE_INTRO:
            props.setters.setStep(CONSTANTS.PAGE_HEALTHCARD);
            break;
        case CONSTANTS.PAGE_HEALTHCARD:
            props.setters.setStep(CONSTANTS.PAGE_INFORMATION);
            break;
        case CONSTANTS.PAGE_INFORMATION:
            props.setters.setStep(CONSTANTS.PAGE_INFORMATION_CONFIRMATION);
            break;
        case CONSTANTS.PAGE_INFORMATION_CONFIRMATION:
            if (shouldPregnancyShow(props.data.sex, props.data.dob)) {
                props.setters.setStep(CONSTANTS.PAGE_PREGNANT);
            } else {
                props.setters.setStep(CONSTANTS.PAGE_VACCINATION);
            }
            break;
        case CONSTANTS.PAGE_PREGNANT:
            props.setters.setStep(CONSTANTS.PAGE_VACCINATION);
            break;
        case CONSTANTS.PAGE_VACCINATION:
            props.setters.setStep(CONSTANTS.PAGE_SYMPTOMS);
            break;
        case CONSTANTS.PAGE_SYMPTOMS:
            if (isAsymptomatic) {
                props.setters.setStep(CONSTANTS.PAGE_GROUP_ASYMPTOMATIC);
            } else {
                props.setters.setStep(CONSTANTS.PAGE_GROUP_SYMPTOMATIC);
            }
            break;
        case CONSTANTS.PAGE_GROUP_ASYMPTOMATIC:
            if (noGroup) {
                // Ineligible
                props.setters.setStep(CONSTANTS.PAGE_LOADING_INELIGIBLE);
            } else {
                if (exposed) {
                    props.setters.setStep(CONSTANTS.PAGE_CONTACT_CASE);
                } else {
                    props.setters.setStep(CONSTANTS.PAGE_APPOINTMENT_LOADING);
                }
            }
            break;
        case CONSTANTS.PAGE_GROUP_SYMPTOMATIC:
            if (noGroup) {
                // Ineligible
                props.setters.setStep(CONSTANTS.PAGE_LOADING_INELIGIBLE);
            } else {
                if (exposed) {
                    props.setters.setStep(CONSTANTS.PAGE_CONTACT_CASE);
                } else {
                    props.setters.setStep(CONSTANTS.PAGE_APPOINTMENT_LOADING);
                }
            }
            break;
        case CONSTANTS.PAGE_CONTACT_CASE:
            props.setters.setStep(CONSTANTS.PAGE_APPOINTMENT_LOADING);
            break;
        case CONSTANTS.PAGE_APPOINTMENT_LOADING:
            props.setters.setStep(CONSTANTS.PAGE_VISIT_DATE_TIME);
            break;
        case CONSTANTS.PAGE_VISIT_DATE_TIME:
            props.setters.setStep(CONSTANTS.PAGE_INFORMATION_LOADING);
            break;
        case CONSTANTS.PAGE_INFORMATION_LOADING:
            props.setters.setStep(CONSTANTS.PAGE_CHECK_IN_SUCCESS);
            break;
        case CONSTANTS.PAGE_LOADING_INELIGIBLE:
            props.setters.setStep(CONSTANTS.PAGE_INELIGIBLE);
            break;
        case CONSTANTS.PAGE_NO_APPOINTMENTS:
            props.setters.setStep(CONSTANTS.PAGE_WAITLIST_LOADING);
            break;
        case CONSTANTS.PAGE_WAITLIST_LOADING:
            props.setters.setStep(CONSTANTS.PAGE_WAITLIST_SUCCESS);
            break;
    }
}

export function goToPreviousPage(props, currentPage) {
    switch (currentPage) {
        case CONSTANTS.PAGE_VISIT_DATE_TIME:
            props.setters.setStep(CONSTANTS.PAGE_ANTIGEN);
            break;
        case CONSTANTS.PAGE_ANTIGEN:
            props.setters.setStep(CONSTANTS.PAGE_GROUP);
            break;
        case CONSTANTS.PAGE_GROUP:
            props.setters.setStep(CONSTANTS.PAGE_TRAVEL);
            break;
        case CONSTANTS.PAGE_TRAVEL:
            props.setters.setStep(CONSTANTS.PAGE_CONTACT_CASE);
            break;
        case CONSTANTS.PAGE_CONTACT_CASE:
            props.setters.setStep(CONSTANTS.PAGE_SYMPTOMS);
            break;
        case CONSTANTS.PAGE_SYMPTOMS:
            props.setters.setStep(CONSTANTS.PAGE_VACCINATION);
            break;
        case CONSTANTS.PAGE_VACCINATION:
            if (shouldPregnancyShow) {
                props.setters.setStep(CONSTANTS.PAGE_PREGNANT);
            } else {
                props.setters.setStep(CONSTANTS.PAGE_INFORMATION);
            }
        case CONSTANTS.PAGE_INFORMATION_CONFIRMATION:
            props.setters.setStep(CONSTANTS.PAGE_INFORMATION);
            break;
        case CONSTANTS.PAGE_INFORMATION:
            props.setters.setStep(CONSTANTS.PAGE_HEALTHCARD);
            break;
    }
}

/**
 *
 * @param props The properties object
 */
export function goToErrorPage(props) {
    props.setters.setStep(CONSTANTS.PAGE_ERROR);
}
