module.exports = Object.freeze({
    PAGE_INTRO: "INTRO",
    PAGE_HAS_HEALTHCARD: "HAS_HEALTHCARD",
    PAGE_INFORMATION: "INFORMATION",
    PAGE_INFORMATION_CONFIRMATION: "INFORMATION_CONFIRMATION",
    PAGE_HEALTHCARD: "HEALTHCARD",
    PAGE_INFORMATION_LOADING: "INFORMATION_LOADING",
    PAGE_SYMPTOMS: "SYMPTOMS",
    PAGE_VACCINATION: "VACCINATION",
    PAGE_PREGNANT: "PREGNANT",
    PAGE_TRAVEL: "TRAVEL",
    PAGE_ANTIGEN: "ANTIGEN",
    PAGE_GROUP_ASYMPTOMATIC: "GROUP_ASYMPTOMATIC",
    PAGE_GROUP_SYMPTOMATIC: "GROUP_SYMPTOMATIC",
    PAGE_CONTACT_CASE: "CONTACT_CASE",
    PAGE_CHECK_IN_SUCCESS: "CHECK_IN_SUCCESS",
    PAGE_VISIT_DATE_TIME: "VISIT_DATE_TIME",
    PAGE_REASON_FOR_TEST: "REASON_FOR_TEST",
    PAGE_APPOINTMENT_LOADING: "APPOINTMENT_LOADING",
    PAGE_LOADING_INELIGIBLE: "LOADING_INELIGIBLE",
    PAGE_INELIGIBLE: "INELIGIBLE",
    PAGE_NO_APPOINTMENTS: "NO_APPOINTMENTS",
    PAGE_WAITLIST_LOADING: "WAITLIST_LOADING",
    PAGE_WAITLIST_SUCCESS: "WAITLIST_SUCCESSS",
    PAGE_ERROR: "ERROR",

    VACCINATION_TWO_OVER_FOURTEEN_DAYS: "Received 2 doses of the COVID-19 vaccine over 14 days ago",
    VACCINATION_TWO_LESS_FOURTEEN_DAYS: "Received 2 doses of the COVID-19 vaccine less than 14 days ago",
    VACCINATION_ONE_VACCINE: "Received 1 dose of the COVID-19 vaccine",
    VACCINATION_UNVACCINATED: "Unvaccinated",

    GROUPS_ASYMPTOMATIC_INDIGENOUS: "indigenous community/worker",
    GROUPS_ASYMPTOMATIC_TRANSFERS: "unvaccinated, being transferred to/from hospital or group setting",
    GROUPS_ASYMPTOMATIC_HIGH_RISK_EXPOSED: "exposure of high risk setting",
    GROUPS_ASYMPTOMATIC_HIGH_RISK_SETTING: "living in a high risk setting",

    GROUPS_SYMPTOMATIC_HEALTHCARE: "patient-facing healthcare worker",
    GROUPS_SYMPTOMATIC_GROUP_SETTING: "person of a hospital or group living setting",
    GROUPS_SYMPTOMATIC_OUT_PATIENT: "an outpatient being considered for COVID-19 treatment",
    GROUPS_SYMPTOMATIC_HOMELESS: "underhoused or homeless",
    GROUPS_SYMPTOMATIC_HIGH_RISK_EXPOSURE: "exposure of high risk setting",

    GROUPS_NONE: "none",

    SYMPTOMS_FEVER: "fever",
    SYMPTOMS_COUGH: "cough",

    SYMPTOMS_SOB: "shortness of breath",
    SYMPTOMS_NASAL_CONGESTION: "nasal congestion",
    SYMPTOMS_ABDOMINAL_PAIN: "abdominal pain",
    SYMPTOMS_HEADACHE: "headache",
    SYMPTOMS_LOSS_SMELL_TASTE: "loss of smell/taste",
    SYMPTOMS_PINK_EYE: "pink eye",
    SYMPTOMS_APPETITE: "loss of appetite",
    SYMPTOMS_SORE_THROAT: "sore throat",
    SYMPTOMS_RUNNY_NOSE: "runny nose",
    SYMPTOMS_FATIGUE: "fatigue",
    SYMPTOMS_MUSCLE_ACHE: "muscle aches",
    SYMPTOMS_DIARRHEA: "diarrhea",
    SYMPTOMS_NAUSEA: "nausea",
    SYMPTOMS_VOMITING: "vomiting",
    SYMPTOMS_ASYMPTOMATIC: "asymptomatic",
});
