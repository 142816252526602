import CONSTANTS from "../constants";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useEffect } from "react";

function WaitlistSuccess(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    dayjs.extend(customParseFormat);

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">You have been successfully added to the waitlist</span>
            </h2>
            <p className="mt-2 text-lg leading-6 text-white">
                Thank you for your submission. We will send you an email as soon as new appointments are available.
            </p>

            <div className="flex justify-end sm:justify-start">
                <button
                    onClick={() => {
                        props.reset();
                        props.setters.setStep(CONSTANTS.PAGE_INTRO);
                    }}
                    className="mt-8 bg-white white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-800 hover:bg-gray-200"
                >
                    Restart
                </button>
            </div>
        </>
    );
}

export default WaitlistSuccess;
