import { useEffect } from "react";
import Spinner from "../images/spinner.svg";
import { goToNextPage, goToErrorPage } from "../navigator";
import CONSTANTS from "../constants";
import * as dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

function AppointmentLoading(props) {
    function getAvailableDates(fromDate, toDate) {
        // const availableDatesResponse = await fetch("/api/availableDates", {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify({
        //         fromDate: fromDate.format("YYYY-MM-DD"),
        //         toDate: toDate.format("YYYY-MM-DD"),
        //     }),
        // });

        // const availableDatesRaw = await availableDatesResponse.json();

        // if (!availableDatesResponse.ok) {
        //     console.log(availableDatesRaw);
        //     return undefined;
        // }

        // const availableDates = availableDatesRaw.map((date) => {
        //     const dateFormatted = date.available_date.date.replaceAll("-", "/"); // yyyy/mm/dd
        //     const parsedDate = dayjs(dateFormatted, "YYYY/MM/DD");

        //     return {
        //         date: dateFormatted,
        //         dateString: parsedDate.format("dddd MMMM D, YYYY"), // Wednesday December 6, 2021
        //     };
        // });

        // If its sunday, add one day
        if (fromDate.day() === 0) {
            return [
                {
                    date: fromDate.plus(1, "day").format("YYYY/MM/DD"),
                    dateString: fromDate.format("dddd MMMM D, YYYY"),
                },
            ];
        } else {
            return [
                {
                    date: fromDate.format("YYYY/MM/DD"),
                    dateString: fromDate.format("dddd MMMM D, YYYY"),
                },
            ];
        }



    }

    function getAvailableSlots(fromDate, toDate) {
        // const availableSlotsResponse = await fetch("/api/availableSlots", {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify({
        //         fromDate: fromDate.format("YYYY-MM-DD"),
        //         toDate: toDate.format("YYYY-MM-DD"),
        //     }),
        // });

        // const availableSlotsRaw = await availableSlotsResponse.json();

        // if (!availableSlotsResponse.ok) {
        //     console.log(availableSlotsRaw);
        //     return undefined;
        // }

        // const availableSlots = availableSlotsRaw.map((slot) => {
        //     const slotTimestamp = slot.slot.timestamp;
        //     // Remove last 6 characters as it is not a valid timestamp
        //     const removedTimeStamp = slotTimestamp.substring(0, slotTimestamp.length - 6);

        //     const parsedDateTime = dayjs(removedTimeStamp, "YYYY-MM-DDTHH:mm:ss");

        //     return {
        //         timeStamp: removedTimeStamp,
        //         date: parsedDateTime.format("YYYY/MM/DD"),
        //         time: parsedDateTime.format("h:mm A"),
        //     };
        // });

        // Saturday
        if (fromDate.day() === 6) {
            return [{ date: fromDate.format("YYYY/MM/DD"), time: "12:30 PM" }];
        } else {
            return [{ date: fromDate.format("YYYY/MM/DD"), time: "4:30 PM" }];
        }
    }

    async function getDocsData() {
        dayjs.extend(customParseFormat);

        const today = dayjs();
        const threeDaysFromToday = today.add(5, "day");

        const availableDates = getAvailableDates(today, threeDaysFromToday);
        const availableSlots = getAvailableSlots(today, threeDaysFromToday);

        console.log("Available dates: " + availableDates);
        console.log("Available slots: " + availableSlots);

        if (availableDates === undefined || availableSlots === undefined) {
            goToErrorPage(props);
        } else {
            props.setters.setAvailableDates(availableDates);
            props.setters.setAvailableSlots(availableSlots);

            let hasSlots;

            if (availableSlots.length > 0) {
                hasSlots = true;
            } else {
                hasSlots = false;
            }

            goToNextPage(props, CONSTANTS.PAGE_APPOINTMENT_LOADING, null, hasSlots);
        }
    }

    useEffect(() => {
        getDocsData();
    }, []);

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Loading</span>
            </h2>
            <p className="mt-4 text-lg leading-6 text-white">We are checking availability.</p>
            <img className="animate-spin mt-8 h-10 w-10 text-blue-500" src={Spinner}></img>
        </>
    );
}

export default AppointmentLoading;
