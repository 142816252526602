import Intro from "./Intro";
import Healthcard from "./Healthcard";
import Information from "./Information";
import InformationLoading from "./InformationLoading";
import InformationConfirmation from "./InformationConfirmation";
import CheckInSuccess from "./CheckInSuccess";
import Error from "./Error";
import Symptoms from "./Symptoms";
import Vaccination from "./Vaccination";
import { useState } from "react";
import Pregnacy from "./Pregnancy";
import GroupsAsymptomatic from "./GroupsAsymptomatic";
import GroupsSymptomatic from "./GroupsSymptomatic";
import ContactCase from "./ContactCase";
import CONSTANTS from "../constants";
import VisitDateTime from "./VisitDateTime";
import AppointmentLoading from "./AppointmentLoading";
import Ineligible from "./Ineligible";
import LoadingIneligible from "./LoadingIneligible";
import NoAppointments from "./NoAppointments";
import WaitlistLoading from "./WaitlistLoading";
import WaitlistSuccess from "./WaitlistSuccess";

function MultistepForm() {
    const [step, setStep] = useState(CONSTANTS.PAGE_INTRO);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dob, setDob] = useState("");
    const [sex, setSex] = useState("");
    const [hc, setHc] = useState("");
    const [versionCode, setVersionCode] = useState("");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [province, setProvince] = useState("ON");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [vaccination, setVaccination] = useState("");
    const [symptoms, setSymptoms] = useState([]);
    const [symptomsOnsetDate, setSymptomsOnsetDate] = useState("");
    const [pregnant, setPregnant] = useState(false);
    const [travel, setTravel] = useState(false);
    const [travelDestination, setTravelDestination] = useState("");
    const [travelReturnDate, setTravelReturnDate] = useState("");
    const [exposure, setExposure] = useState(false);
    const [placeOfExposure, setPlaceOfExposure] = useState("");
    const [exposureDetails, setExposureDetails] = useState("");
    const [investigationNumber, setInvestigationNumber] = useState("");
    const [dateOfExposure, setDateOfExposure] = useState("");
    const [dateOfContactSymptom, setDateOfContactSymptom] = useState("");
    const [groups, setGroups] = useState([]);
    const [ltcHomeName, setLtcHomeName] = useState("");
    const [antigen, setAntigen] = useState(undefined);
    const [reasonForTest, setReasonForTest] = useState("");
    const [timeSlot, setTimeSlot] = useState("");
    const [dateOfTest, setDateOfTest] = useState("");
    const [timeOfTest, setTimeOfTest] = useState("");
    const [timeSlotList, setTimeSlotList] = useState([]);

    const [availableDates, setAvailableDates] = useState([]);
    const [availableSlots, setAvailableSlots] = useState([]);

    const resetState = () => {
        setFirstName("");
        setLastName("");
        setDob("");
        setSex("");
        setHc("");
        setVersionCode("");
        setVaccination("");
        setSymptoms([]);
        setSymptomsOnsetDate("");
        setPregnant(false);
        setTravel(false);
        setTravelDestination("");
        setTravelReturnDate("");
        setExposure(false);
        setPlaceOfExposure("");
        setExposureDetails("");
        setInvestigationNumber("");
        setDateOfExposure("");
        setDateOfContactSymptom("");
        setGroups([]);
        setLtcHomeName("");
        setAntigen(false);
        setReasonForTest("");
        setTimeSlot("");
        setDateOfTest("");
        setTimeOfTest("");
        setTimeSlotList([]);
        setAvailableDates([]);
        setAvailableSlots([]);
    };

    const props = {
        data: {
            step,
            firstName,
            lastName,
            dob,
            sex,
            hc,
            versionCode,
            street,
            city,
            province,
            phone,
            email,
            postalCode,
            vaccination,
            symptoms,
            symptomsOnsetDate,
            pregnant,
            travel,
            travelDestination,
            travelReturnDate,
            antigen,
            exposure,
            placeOfExposure,
            exposureDetails,
            investigationNumber,
            dateOfExposure,
            dateOfContactSymptom,
            groups,
            ltcHomeName,
            reasonForTest,
            timeSlot,
            dateOfTest,
            timeOfTest,
            timeSlotList,
            availableDates,
            availableSlots,
        },
        setters: {
            setStep,
            setFirstName,
            setLastName,
            setDob,
            setSex,
            setHc,
            setVersionCode,
            setStreet,
            setCity,
            setProvince,
            setPhone,
            setEmail,
            setPostalCode,
            setVaccination,
            setSymptoms,
            setSymptomsOnsetDate,
            setPregnant,
            setTravel,
            setTravelDestination,
            setTravelReturnDate,
            setAntigen,
            setExposure,
            setExposureDetails,
            setPlaceOfExposure,
            setInvestigationNumber,
            setDateOfExposure,
            setDateOfContactSymptom,
            setGroups,
            setLtcHomeName,
            setReasonForTest,
            setTimeSlot,
            setDateOfTest,
            setTimeOfTest,
            setTimeSlotList,
            setAvailableDates,
            setAvailableSlots,
        },
        reset: resetState,
    };

    switch (step) {
        case CONSTANTS.PAGE_INTRO:
            return <Intro {...props} />;
        case CONSTANTS.PAGE_HEALTHCARD:
            return <Healthcard {...props} />;
        case CONSTANTS.PAGE_INFORMATION:
            return <Information {...props} />;
        case CONSTANTS.PAGE_INFORMATION_CONFIRMATION:
            return <InformationConfirmation {...props} />;
        case CONSTANTS.PAGE_INFORMATION_LOADING:
            return <InformationLoading {...props} />;
        case CONSTANTS.PAGE_SYMPTOMS:
            return <Symptoms {...props} />;
        case CONSTANTS.PAGE_VACCINATION:
            return <Vaccination {...props} />;
        case CONSTANTS.PAGE_PREGNANT:
            return <Pregnacy {...props} />;
        case CONSTANTS.PAGE_GROUP_ASYMPTOMATIC:
            return <GroupsAsymptomatic {...props} />;
        case CONSTANTS.PAGE_GROUP_SYMPTOMATIC:
            return <GroupsSymptomatic {...props} />;
        case CONSTANTS.PAGE_CONTACT_CASE:
            return <ContactCase {...props} />;
        case CONSTANTS.PAGE_CHECK_IN_SUCCESS:
            return <CheckInSuccess {...props} />;
        case CONSTANTS.PAGE_VISIT_DATE_TIME:
            return <VisitDateTime {...props} />;
        case CONSTANTS.PAGE_ERROR:
            return <Error {...props} />;
        case CONSTANTS.PAGE_APPOINTMENT_LOADING:
            return <AppointmentLoading {...props} />;
        case CONSTANTS.PAGE_LOADING_INELIGIBLE:
            return <LoadingIneligible {...props} />;
        case CONSTANTS.PAGE_INELIGIBLE:
            return <Ineligible {...props} />;
        case CONSTANTS.PAGE_NO_APPOINTMENTS:
            return <NoAppointments {...props} />;
        case CONSTANTS.PAGE_WAITLIST_LOADING:
            return <WaitlistLoading {...props} />;
        case CONSTANTS.PAGE_WAITLIST_SUCCESS:
            return <WaitlistSuccess {...props} />;
        default:
            return null;
    }
}

export default MultistepForm;
