import { goToNextPage } from "../navigator";
import CONSTANTS from "../constants";

function Intro(props) {
    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">COVID-19 Testing</span>
            </h2>
            <p className="mt-4 text-lg leading-6 text-white">
                Please register for COVID-19 testing. All of your information is private.
            </p>
            <p className="mt-4 text-lg leading-6 text-red-500">
                NOTE: Do not book appointments at multiple locations as this significantly affects the capacity of testing
                centers.
            </p>
            <button
                onClick={() => {
                    goToNextPage(props, CONSTANTS.PAGE_INTRO);
                }}
                className="mt-8 bg-white white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-800 hover:bg-gray-200"
            >
                Get started
            </button>
            <p className="mt-4 text-sm leading-6 text-gray-400 italic">
                By clicking the button above, you consent to registering for the COVID-19 testing and your information being
                collected.
            </p>
        </>
    );
}

export default Intro;
