import { useEffect } from "react";
import CONSTANTS from "../constants";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

function CheckInSuccess(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    dayjs.extend(customParseFormat);

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Registration successful!</span>
            </h2>
            <p className="mt-2 text-lg leading-6 text-white">
                You appointment has been booked for{" "}
                <span className="font-bold underline decoration-blue-500">
                    {dayjs(props.data.dateOfTest, "YYYY/MM/DD").format("dddd MMMM D, YYYY")}
                </span>{" "}
                at <span className="font-bold underline decoration-blue-500">{props.data.timeOfTest}</span>. Please aim to arrive
                no more than 5 minutes ahead of your appointment at 140 Hespeler Road, Cambridge. An email confirmation with
                instructions has also been sent to you.
            </p>

            <h2 className="text-xl font-extrabold text-white sm:text-xl mt-8">
                <span className="block">When you arrive</span>
            </h2>
            <p className="mt-2 text-lg leading-6 text-white">
                Stay in your car and call (519) 267-5030.
            </p>

            <div className="flex justify-end sm:justify-start">
                <button
                    onClick={() => {
                        props.reset();
                        props.setters.setStep(CONSTANTS.PAGE_INTRO);
                    }}
                    className="mt-8 bg-white white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-800 hover:bg-gray-200"
                >
                    Register another patient
                </button>
            </div>
        </>
    );
}

export default CheckInSuccess;
