import { useState, useEffect } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { goToNextPage } from "../navigator";
import CONSTANTS from "../constants";

const groupsInitial = [
    {
        id: 0,
        group: "Patient-facing health care worker",
        canonical: CONSTANTS.GROUPS_SYMPTOMATIC_HEALTHCARE,
        checked: false,
    },
    {
        id: 1,
        group: "A staff member, volunteer, resident, inpatient, essential care provider, or visitor in a hospital or congregate living setting (including long-term care, retirement homes, First Nation elder care lodges, group homes, shelters, hospices, temporary foreign worker settings and correctional institutions)",
        canonical: CONSTANTS.GROUPS_SYMPTOMATIC_GROUP_SETTING,
        checked: false,
    },
    {
        id: 2,
        group: "An outpatient being considered for COVID-19 treatment",
        canonical: CONSTANTS.GROUPS_SYMPTOMATIC_OUT_PATIENT,
        checked: false,
    },
    {
        id: 4,
        group: "Underhoused or homeless",
        canonical: CONSTANTS.GROUPS_SYMPTOMATIC_HOMELESS,
        checked: false,
    },
    {
        id: 5,
        group: "Have been exposed, or a close contact of someone exposed, to a confirmed or suspected outbreak in a high risk setting, including a hospital, long-term care, retirement home, other congregate living setting or institution",
        canonical: CONSTANTS.GROUPS_SYMPTOMATIC_HIGH_RISK_EXPOSURE,
        checked: false,
    },
    {
        id: 99,
        group: "None of the above",
        canonical: CONSTANTS.GROUPS_NONE,
        checked: false,
    },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function GroupsSymptomatic(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [groups, setGroups] = useState(groupsInitial);
    const [longTerm, setLongTerm] = useState(false);
    const [longTermName, setLongTermName] = useState("");
    // Error when both groups and none are selected
    const [dualError, setDualError] = useState(false);
    // Error when none are selected
    const [noneError, setNoneError] = useState(false);
    // Error when facility name is null
    const [longTermError, setLongTermError] = useState(false);

    const handleOnChange = (position) => {
        setDualError(false);
        setNoneError(false);
        setLongTermError(false);

        const updatedState = groups.map((item, index) => {
            if (index === position) {
                return { ...item, checked: !groups[index].checked };
            } else {
                return item;
            }
        });

        let isLongTerm = false;
        updatedState.forEach((group, index) => {
            if ((group.id === 0 || group.id === 1 || group.id === 5) && group.checked) {
                isLongTerm = true;
            }
        });
        setLongTerm(isLongTerm);

        setGroups(updatedState);
    };

    const longTermHandler = (value) => {
        setLongTermName(value.toUpperCase());
    };

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Do you belong to any of the following groups?</span>
            </h2>
            <p className="my-4 text-lg leading-6 text-white">If not, select none of the above at the bottom</p>
            <div className="max-w-lg bg-white rounded-md p-4">
                <div className="rounded-md">
                    <fieldset className="space-y-5">
                        {groupsInitial.map((sx, index) => {
                            return (
                                <div className="relative flex items-start " key={index}>
                                    <div className="flex items-center h-5">
                                        <input
                                            id={`group-${index}`}
                                            name={sx.group}
                                            type="checkbox"
                                            checked={groups[index].checked}
                                            onChange={() => handleOnChange(index)}
                                            className="focus:ring-blue-500 h-4 w-4 text-blue-500 border-gray-300 rounded"
                                        />
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label htmlFor={`group-${index}`} className="font-medium text-gray-700">
                                            {sx.group}
                                        </label>
                                    </div>
                                </div>
                            );
                        })}
                    </fieldset>
                </div>
            </div>

            <p className={classNames(!dualError && "hidden", "mt-3 text-sm text-red-500")}>
                {dualError && "Please select either a group or select none of the above."}
            </p>
            <p className={classNames(!noneError && "hidden", "mt-3 text-sm text-red-500")}>
                {noneError &&
                    "Please make a selection. If you do not belong to any of the groups above, select None of the above."}
            </p>

            {/* Name of facility */}
            {longTerm && (
                <div className="md:max-w-lg mt-8">
                    <label htmlFor="name" className="block text-sm font-medium text-white">
                        Name of facility/employer/retirement home, etc
                    </label>
                    <div className="mt-1 relative">
                        <input
                            type="text"
                            name="name"
                            id="name"
                            value={longTermName}
                            onChange={(e) => {
                                longTermHandler(e.target.value);
                            }}
                            className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                    </div>
                </div>
            )}
            <p className={classNames(!longTermError && "hidden", "mt-3 text-sm text-red-500")}>
                {longTermError && "Name of facility must be entered"}
            </p>

            <div className="flex justify-end sm:justify-start">
                <button
                    onClick={() => {
                        let isValid = true;

                        let noneError = true;
                        let groupsChecked = false;
                        let noneOfTheAboveChecked = false;
                        let needsToEnterFacilityName = false;

                        groups.forEach((sx) => {
                            if (sx.checked) {
                                noneError = false;
                            }

                            if (sx.checked && ((sx.id === 0 || sx.id === 1 || sx.id === 5))) {
                                needsToEnterFacilityName = true;
                            }

                            if (sx.id !== 99) {
                                if (sx.checked) {
                                    groupsChecked = true;
                                }
                            } else {
                                noneOfTheAboveChecked = sx.checked;
                            }
                            
                        });

                        if (noneError) {
                            isValid = false;
                            setNoneError(true);
                        }

                        if (groupsChecked && noneOfTheAboveChecked) {
                            isValid = false;
                            setDualError(true);
                        }

                        if (needsToEnterFacilityName && longTermName.trim() === "") {
                            setLongTermError(true);
                            isValid = false;
                        }

                        if (isValid) {
                            let groupsArr = [];

                            let exposed = false;
                            let none = false;

                            groups.forEach((group) => {
                                if (group.checked) {
                                    groupsArr.push(group.canonical);
                                }

                                if (group.id === 5 && group.checked) {
                                    exposed = true;
                                }

                                if (group.id === 99 && group.checked) {
                                    none = true;
                                }
                            });

                            props.setters.setGroups(groupsArr);
                            props.setters.setLtcHomeName(longTermName.trim());

                            goToNextPage(props, CONSTANTS.PAGE_GROUP_SYMPTOMATIC, undefined, undefined, undefined, exposed, none);
                        }
                    }}
                    className="mt-8 bg-white white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-800 hover:bg-gray-200"
                >
                    Next
                </button>
            </div>
        </>
    );
}

export default GroupsSymptomatic;
