import { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import format from "../Util/masker";
import { goToNextPage, goToPreviousPage } from "../navigator";
import CONSTANTS from "../constants";
import { ArrowLeftIcon } from "@heroicons/react/solid";

const symptomsInitial = [
    {
        id: 0,
        symptom: "Fever/chills",
        canonical: CONSTANTS.SYMPTOMS_FEVER,
        checked: false,
    },
    {
        id: 1,
        symptom: "New onset of cough",
        canonical: CONSTANTS.SYMPTOMS_COUGH,
        checked: false,
    },
    {
        id: 2,
        symptom: "Shortness of breath",
        canonical: CONSTANTS.SYMPTOMS_SOB,
        checked: false,
    },
    {
        id: 3,
        symptom: "Nasal congestion",
        canonical: CONSTANTS.SYMPTOMS_NASAL_CONGESTION,
        checked: false,
    },
    {
        id: 6,
        symptom: "Loss of smell and/or taste",
        canonical: CONSTANTS.SYMPTOMS_LOSS_SMELL_TASTE,
        checked: false,
    },
    {
        id: 9,
        symptom: "Sore throat",
        canonical: CONSTANTS.SYMPTOMS_SORE_THROAT,
        checked: false,
    },
    {
        id: 10,
        symptom: "Runny nose",
        canonical: CONSTANTS.SYMPTOMS_RUNNY_NOSE,
        checked: false,
    },
    {
        id: 11,
        symptom: "Fatigue (general feeling of being unwell) that is unusual or unexplained",
        canonical: CONSTANTS.SYMPTOMS_FATIGUE,
        checked: false,
    },
    {
        id: 12,
        symptom: "Muscle aches or pain that are unusual or unexplained",
        canonical: CONSTANTS.SYMPTOMS_MUSCLE_ACHE,
        checked: false,
    },
    {
        id: 13,
        symptom: "Diarrhea",
        canonical: CONSTANTS.SYMPTOMS_DIARRHEA,
        checked: false,
    },
    {
        id: 14,
        symptom: "Nausea",
        canonical: CONSTANTS.SYMPTOMS_NAUSEA,
        checked: false,
    },
    {
        id: 16,
        symptom: "Vomiting",
        canonical: CONSTANTS.SYMPTOMS_VOMITING,
        checked: false,
    },
    {
        id: 99,
        symptom: "None of the above (asymptomatic)",
        canonical: CONSTANTS.SYMPTOMS_ASYMPTOMATIC,
        checked: false,
    },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function Symptoms(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [symptoms, setSymptoms] = useState(symptomsInitial);
    const [symptomatic, setSymptomatic] = useState(false);
    const [symptomsDate, setSymptomsDate] = useState("");
    // Error when both symptoms and asymptomatic are selected
    const [dualError, setDualError] = useState(false);
    // Error when none are selected
    const [noneError, setNoneError] = useState(false);
    const [dateError, setDateError] = useState(false);

    const handleOnChange = (position) => {
        setDualError(false);
        setNoneError(false);
        setDateError(false);

        const updatedState = symptoms.map((item, index) => {
            if (index === position) {
                return { ...item, checked: !symptoms[index].checked };
            } else {
                return item;
            }
        });

        let isSymptomatic = false;
        updatedState.forEach((sx) => {
            if (sx.id !== 99 && sx.checked) {
                isSymptomatic = true;
            }
        });

        setSymptomatic(isSymptomatic);

        setSymptoms(updatedState);
    };

    const symptomDateHandler = (value) => {
        setDateError(false);
        const strippedValue = value.replaceAll("/", "").replaceAll(" ", "");
        if (value.length <= 10 && !isNaN(+strippedValue)) {
            setSymptomsDate(format("XXXX/XX/XX", strippedValue));
        }
    };

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Are you experiencing any of the following symptoms?</span>
            </h2>
            <p className="my-4 text-lg leading-6 text-white">
                Select all that apply. If you are asymptomatic, select "none of the above".
            </p>
            <div className="max-w-lg bg-white rounded-md p-4">
                <div className="rounded-md">
                    <fieldset className="space-y-5">
                        {symptomsInitial.map((sx, index) => {
                            return (
                                <div className="relative flex items-start " key={index}>
                                    <div className="flex items-center h-5">
                                        <input
                                            id={`symptom-${index}`}
                                            name={sx.symptom}
                                            type="checkbox"
                                            checked={symptoms[index].checked}
                                            onChange={() => handleOnChange(index)}
                                            className="focus:ring-blue-500 h-4 w-4 text-blue-500 border-gray-300 rounded"
                                        />
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label htmlFor={`symptom-${index}`} className="font-medium text-gray-700">
                                            {sx.symptom}
                                        </label>
                                    </div>
                                </div>
                            );
                        })}
                    </fieldset>
                    <p className={classNames(!dualError && "hidden", "mt-3 text-sm text-red-500")}>
                        {dualError && "Please select either asymptomatic or select the symptoms you are experiencing."}{" "}
                    </p>
                    <p className={classNames(!noneError && "hidden", "mt-3 text-sm text-red-500")}>
                        {noneError && "Please make a selection. If you have no symptoms, select None of the above."}
                    </p>
                </div>
            </div>
            {/* Date of symptoms start */}
            {symptomatic && (
                <div className="md:max-w-lg mt-8">
                    <label htmlFor="date" className="block text-sm font-medium text-white">
                        Date of symptom onset (yyyy/mm/dd)
                    </label>
                    <div className="mt-1 relative">
                        <input
                            type="text"
                            name="date"
                            id="date"
                            value={symptomsDate}
                            inputMode="numeric"
                            placeholder="yyyy/mm/dd"
                            onChange={(e) => {
                                symptomDateHandler(e.target.value);
                            }}
                            className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        <div
                            className={classNames(
                                !dateError && "invisible",
                                "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                            )}
                        >
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                        </div>
                    </div>
                    <p className={classNames(!dateError && "invisible", "mt-1 text-sm text-red-500")}>Enter a valid date.</p>
                </div>
            )}

            <div className="flex justify-end sm:justify-start">
                <button
                    onClick={() => {
                        let isValid = true;

                        let noneError = true;
                        let symptomsChecked = false;
                        let asymptomaticChecked = false;

                        symptoms.forEach((sx) => {
                            if (sx.checked) {
                                noneError = false;
                            }

                            if (sx.id !== 99) {
                                if (sx.checked) {
                                    symptomsChecked = true;
                                }
                            } else {
                                asymptomaticChecked = sx.checked;
                            }
                        });

                        if (noneError) {
                            isValid = false;
                            setNoneError(true);
                        }

                        if (symptomsChecked && asymptomaticChecked) {
                            isValid = false;
                            setDualError(true);
                        }

                        if (!asymptomaticChecked) {
                            if (symptomsDate.length === 10) {
                                const dates = symptomsDate.split("/");

                                const year = parseInt(dates[0]);
                                const month = parseInt(dates[1]);
                                const day = parseInt(dates[2]);

                                if (!(year > 2020 && year < 2030 && month > 0 && month <= 12 && day > 0 && day <= 31)) {
                                    isValid = false;
                                    setDateError(true);
                                }
                            } else {
                                isValid = false;
                                setDateError(true);
                            }
                        }

                        if (isValid) {
                            let symptomsArr = [];

                            symptoms.forEach((sx) => {
                                if (sx.checked) {
                                    symptomsArr.push(sx.canonical);
                                }
                            });

                            props.setters.setSymptoms(symptomsArr);
                            // Only set the date if symptomatic
                            if (!asymptomaticChecked) {
                                props.setters.setSymptomsOnsetDate(symptomsDate);
                            }

                            goToNextPage(props, CONSTANTS.PAGE_SYMPTOMS, undefined, undefined, asymptomaticChecked);
                        }
                    }}
                    className="mt-8 bg-white white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-800 hover:bg-gray-200"
                >
                    Next
                </button>
            </div>
        </>
    );
}

export default Symptoms;
