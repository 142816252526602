import { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import { goToNextPage, goToPreviousPage } from "../navigator";
import CONSTANTS from "../constants";
import format from "../Util/masker";
import { ArrowLeftIcon } from "@heroicons/react/solid";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function ContactCase(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [outbreakNumber, setOutbreakNumber] = useState("");
    const [dateOfContact, setDateOfContact] = useState("");
    const [dateOfContactOnset, setDateOfContactOnset] = useState("");
    const [exposureDetails, setExposureDetails] = useState("");
    const [placeOfExposure, setPlaceOfExposure] = useState("");

    const [dateOfContactError, setDateOfContactError] = useState(false);
    const [dateOfContactOnsetError, setDateOfContactOnsetError] = useState(false);
    const [placeOfExposureError, setPlaceOfExposureError] = useState(false);

    const outbreakNumberHandler = (value) => {
        setOutbreakNumber(value.toUpperCase());
    };

    const exposureDetailsHandler = (value) => {
        setExposureDetails(value);
    };

    const placeOfExposureHandler = (value) => {
        setPlaceOfExposureError(false);
        setPlaceOfExposure(value);
    };

    const dateOfContactHandler = (value) => {
        setDateOfContactError(false);
        const strippedValue = value.replaceAll("/", "").replaceAll(" ", "");
        if (value.length <= 10 && !isNaN(+strippedValue)) {
            setDateOfContact(format("XXXX/XX/XX", strippedValue));
        }
    };

    const dateOfContactOnsetHandler = (value) => {
        setDateOfContactOnsetError(false);
        const strippedValue = value.replaceAll("/", "").replaceAll(" ", "");
        if (value.length <= 10 && !isNaN(+strippedValue)) {
            setDateOfContactOnset(format("XXXX/XX/XX", strippedValue));
        }
    };

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Please enter details of your exposure</span>
            </h2>
            <p className="my-4 text-lg leading-6 text-white">Details regarding your exposure must be entered</p>

            {/* Exposure establishment */}
            <div className="md:max-w-lg mt-8">
                <label htmlFor="placeOfExposure" className="block text-sm font-medium text-white">
                    Name of establishment or place where exposure occurred
                </label>
                <div className="mt-1 relative">
                    <input
                        type="text"
                        name="placeOfExposure"
                        id="placeOfExposure"
                        value={placeOfExposure}
                        onChange={(e) => {
                            placeOfExposureHandler(e.target.value);
                        }}
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                </div>
            </div>
            <p className={classNames(!placeOfExposureError && "hidden", "mt-1 text-sm text-red-500")}>
                Place of exposure must be entered.
            </p>

            {/* Date of exposure */}
            <div className="md:max-w-lg mt-8">
                <label htmlFor="exposureDate" className="block text-sm font-medium text-white">
                    Date of exposure (yyyy/mm/dd)
                </label>
                <div className="mt-1 relative">
                    <input
                        type="text"
                        name="exposureDate"
                        id="exposureDate"
                        value={dateOfContact}
                        inputMode="numeric"
                        placeholder="yyyy/mm/dd"
                        onChange={(e) => {
                            dateOfContactHandler(e.target.value);
                        }}
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                </div>
            </div>
            <p className={classNames(!dateOfContactError && "hidden", "mt-1 text-sm text-red-500")}>
                Please enter a valid date. If you are unsure, enter the last day you were at the location.
            </p>

            {/* Exposure details*/}
            <div className="md:max-w-lg mt-8">
                <label htmlFor="outbreakNumber" className="block text-sm font-medium text-white">
                    Details of exposure. Enter any other relevant information regarding the exposure.
                </label>
                <div className="mt-1">
                    <textarea
                        rows={4}
                        name="outbreakNumber"
                        id="outbreakNumber"
                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        defaultValue={""}
                        onChange={(e) => {
                            exposureDetailsHandler(e.target.value);
                        }}
                    />
                </div>
            </div>

            {/* Outbreak number */}
            <div className="md:max-w-lg mt-8">
                <label htmlFor="outbreakNumber" className="block text-sm font-medium text-white">
                    Investigation or outbreak number, if known. This is provided by Public Health.
                </label>
                <div className="mt-1 relative">
                    <input
                        type="text"
                        name="outbreakNumber"
                        id="outbreakNumber"
                        value={outbreakNumber}
                        onChange={(e) => {
                            outbreakNumberHandler(e.target.value);
                        }}
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                </div>
            </div>

            {/* Date of symptom onset */}
            <div className="md:max-w-lg mt-8">
                <label htmlFor="exposureDate" className="block text-sm font-medium text-white">
                    Date of symptom onset of contact, if known (yyyy/mm/dd)
                </label>
                <div className="mt-1 relative">
                    <input
                        type="text"
                        name="exposureDate"
                        id="exposureDate"
                        value={dateOfContactOnset}
                        inputMode="numeric"
                        placeholder="yyyy/mm/dd"
                        onChange={(e) => {
                            dateOfContactOnsetHandler(e.target.value);
                        }}
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                </div>
            </div>
            <p className={classNames(!dateOfContactOnsetError && "hidden", "mt-1 text-sm text-red-500")}>
                Please enter a valid date
            </p>

            <div className="flex justify-end sm:justify-start">
                <button
                    onClick={() => {
                        let isValid = true;

                        if (placeOfExposure === "") {
                            setPlaceOfExposureError(true);
                            isValid = false;
                        }

                        if (dateOfContact.length === 10) {
                            const dates = dateOfContact.split("/");

                            const year = parseInt(dates[0]);
                            const month = parseInt(dates[1]);
                            const day = parseInt(dates[2]);

                            if (!(year > 2020 && year < 2030 && month > 0 && month <= 12 && day > 0 && day <= 31)) {
                                isValid = false;
                                setDateOfContactError(true);
                            }
                        } else {
                            isValid = false;
                            setDateOfContactError(true);
                        }

                        if (dateOfContactOnset.length === 10) {
                            const dates = dateOfContactOnset.split("/");

                            const year = parseInt(dates[0]);
                            const month = parseInt(dates[1]);
                            const day = parseInt(dates[2]);

                            if (!(year > 2020 && year < 2030 && month > 0 && month <= 12 && day > 0 && day <= 31)) {
                                isValid = false;
                                setDateOfContactOnsetError(true);
                            }
                        } else if (dateOfContactOnset.length === 0) {
                            // Do nothing, still valid
                        } else {
                            isValid = false;
                            setDateOfContactOnsetError(true);
                        }

                        if (isValid) {
                            props.setters.setExposure(true);
                            props.setters.setExposureDetails(exposureDetails.trim());
                            props.setters.setInvestigationNumber(outbreakNumber.trim());
                            props.setters.setDateOfExposure(dateOfContact.trim());
                            props.setters.setDateOfContactSymptom(dateOfContactOnset.trim());
                            props.setters.setPlaceOfExposure(placeOfExposure.trim());
                            goToNextPage(props, CONSTANTS.PAGE_CONTACT_CASE);
                        }
                    }}
                    className="mt-8 bg-white white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-800 hover:bg-gray-200"
                >
                    Next
                </button>
            </div>
        </>
    );
}

export default ContactCase;
