import { useEffect } from "react";
import Spinner from "../images/spinner.svg";
import { PDFDocument, StandardFonts, rgb, PageSizes } from "pdf-lib";
import * as base64 from "byte-base64";
import download from "downloadjs";
import CONSTANTS from "../constants";
import { goToNextPage, goToErrorPage } from "../navigator";
import stringSplitter from "../Util/stringSplit";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { addIneligibleDataToGoogleSheets } from "../Util/googleHelper";


function LoadingIneligible(props) {
    async function createPDF() {
        // Create ineligible screening
        const screeningDoc = await PDFDocument.create();
        const helvetica = await screeningDoc.embedFont(StandardFonts.Helvetica);
        const fontSizeData = 10;

        const screeningPage = screeningDoc.addPage(PageSizes.Letter);

        screeningPage.moveTo(15, screeningPage.getSize().height - 30);

        screeningPage.drawText(`COVID-19 PCR Screening for ${props.data.lastName}, ${props.data.firstName}`, {
            size: 18,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(25);

        // Demographics
        screeningPage.drawText(`Demographics`, {
            size: 15,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`DOB: ${props.data.dob} (YYYY/MM/DD)`, {
            size: fontSizeData,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`HC#: ${props.data.hc} ${props.data.versionCode}`, {
            size: fontSizeData,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Sex: ${props.data.sex}`, {
            size: fontSizeData,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Phone #: ${props.data.phone}`, {
            size: fontSizeData,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Email: ${props.data.email}`, {
            size: fontSizeData,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(
            `Address: ${props.data.street}, ${props.data.city}, ${props.data.province}, ${props.data.postalCode}`,
            {
                size: fontSizeData,
                font: helvetica,
                color: rgb(0, 0, 0),
            }
        );

        screeningPage.moveDown(25);

        // Vaccination status
        screeningPage.drawText(`Vaccination`, {
            size: 15,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Vaccination: ${props.data.vaccination}`, {
            size: fontSizeData,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(25);

        // Vaccination status
        screeningPage.drawText(`Symptoms`, {
            size: 15,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        const symptomsCommaSeperated = props.data.symptoms.join(", ");
        const symptomsSplit = stringSplitter(symptomsCommaSeperated, 115);

        if (symptomsSplit.length > 1) {
            symptomsSplit.forEach((line, index) => {
                if (index === 0) {
                    screeningPage.drawText(`Symptoms: ${line}-`, {
                        size: fontSizeData,
                        font: helvetica,
                        color: rgb(0, 0, 0),
                    });
                } else if (index === symptomsSplit.length - 1) {
                    screeningPage.drawText(`${line}`, {
                        size: fontSizeData,
                        font: helvetica,
                        color: rgb(0, 0, 0),
                    });
                } else {
                    screeningPage.drawText(`${line}-`, {
                        size: fontSizeData,
                        font: helvetica,
                        color: rgb(0, 0, 0),
                    });
                }

                screeningPage.moveDown(15);
            });
        } else {
            screeningPage.drawText(`Symptoms: ${symptomsCommaSeperated}`, {
                size: fontSizeData,
                font: helvetica,
                color: rgb(0, 0, 0),
            });

            screeningPage.moveDown(15);
        }

        screeningPage.drawText(`Date of symptom onset: ${props.data.symptomsOnsetDate}`, {
            size: fontSizeData,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(25);

        // Exposure
        screeningPage.drawText(`Exposure`, {
            size: 15,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Has had exposure: ${props.data.exposure}`, {
            size: fontSizeData,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Place of exposure: ${props.data.placeOfExposure}`, {
            size: fontSizeData,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Date of exposure: ${props.data.dateOfExposure}`, {
            size: fontSizeData,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        if (props.data.exposureDetails === "") {
            screeningPage.drawText(`Exposure details:`, {
                size: fontSizeData,
                font: helvetica,
                color: rgb(0, 0, 0),
            });

            screeningPage.moveDown(15);
        } else {
            const exposureSplit = stringSplitter(props.data.exposureDetails, 115);

            if (exposureSplit.length > 1) {
                exposureSplit.forEach((line, index) => {
                    if (index === 0) {
                        screeningPage.drawText(`Exposure details: ${line}-`, {
                            size: fontSizeData,
                            font: helvetica,
                            color: rgb(0, 0, 0),
                        });
                    } else if (index === exposureSplit.length - 1) {
                        screeningPage.drawText(`${line}`, {
                            size: fontSizeData,
                            font: helvetica,
                            color: rgb(0, 0, 0),
                        });
                    } else {
                        screeningPage.drawText(`${line}-`, {
                            size: fontSizeData,
                            font: helvetica,
                            color: rgb(0, 0, 0),
                        });
                    }

                    screeningPage.moveDown(15);
                });
            } else {
                screeningPage.drawText(`Exposure details: ${props.data.exposureDetails}`, {
                    size: fontSizeData,
                    font: helvetica,
                    color: rgb(0, 0, 0),
                });

                screeningPage.moveDown(15);
            }
        }

        screeningPage.drawText(`Investigation #: ${props.data.investigationNumber}`, {
            size: fontSizeData,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Date of symptoms onset of contact: ${props.data.dateOfContactSymptom}`, {
            size: fontSizeData,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(25);

        // Groups
        screeningPage.drawText(`Groups`, {
            size: 15,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Groups: ${props.data.groups.join(", ")}`, {
            size: fontSizeData,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Long term care home name: ${props.data.ltcHomeName}`, {
            size: fontSizeData,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(25);

        // Pregnancy
        screeningPage.drawText(`Pregnacy`, {
            size: 15,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Pregnant: ${props.data.pregnant}`, {
            size: fontSizeData,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(25);

        // Metadata
        screeningPage.drawText(`Metadata`, {
            size: 15,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        const dateSubmitted = dayjs().format("YYYY/MM/DD");
        const timeSubmitted = dayjs().format("h:mm A");

        screeningPage.drawText(`Date submitted: ${dateSubmitted} (YYYY/MM/DD)`, {
            size: fontSizeData,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        screeningPage.moveDown(15);

        screeningPage.drawText(`Time submitted: ${timeSubmitted}`, {
            size: fontSizeData,
            font: helvetica,
            color: rgb(0, 0, 0),
        });

        const screeningBytes = await screeningDoc.save();
        const screeningAttachment = base64.bytesToBase64(screeningBytes);

        const msg = {
            to: "emeraldpharmacyrx@gmail.com",
            from: "no-reply@cloudmedical.ca",
            subject: `Ineligible COVID-19 Testing Submission from ${props.data.firstName} ${props.data.lastName}`,
            attachments: [
                {
                    filename: `Screening ${props.data.firstName} ${props.data.lastName}.pdf`,
                    content: screeningAttachment,
                    encoding: "base64",
                },
            ],
            text: `A patient has just submitted ineligble COVID-19 screening. Their information is below: \n\nName: ${
                props.data.lastName
            }, ${props.data.firstName}\nHealthcard #: ${props.data.hc}${props.data.versionCode}\nDate of birth: ${dayjs(
                props.data.dob,
                "YYYY/MM/DD"
            ).format("DD/MM/YYYY")}\nSex: ${props.data.sex}\nAddress: ${props.data.street}, ${props.data.city}, ${
                props.data.province
            }, ${props.data.postalCode}\nPhone number: ${props.data.phone}\nEmail address: ${props.data.email}`,
        };

        const wrapper = {
            message: msg,
        };

        const ptDob = dayjs(props.data.dob, "YYYY/MM/DD").format("YYYYMMDD");
        const currentDate = dayjs().format("YYYYMMDD");
        const age = Math.floor((currentDate - ptDob) * 0.0001);

        let sHc = props.data.hc;

        if (age > 24 && age < 65) {
            sHc = ("S" + sHc);
        }

        fetch("/api/sendEmail", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(wrapper),
        })
            .then((res) => res.json())
            .then((data) => {
                addIneligibleDataToGoogleSheets(
                    props.data.lastName + "," + props.data.firstName,
                    dayjs(props.data.dob, "YYYY/MM/DD").format("DDMMYYYY"),
                    props.data.sex === "M" ? "Male" : "Female",
                    sHc,
                    dateSubmitted,
                    timeSubmitted
                )
                    .then((data) => {
                        goToNextPage(props, CONSTANTS.PAGE_LOADING_INELIGIBLE);
                    })
                    .catch((e) => {
                        // Error occurred with submitting data but we still want to move forward
                        goToNextPage(props, CONSTANTS.PAGE_LOADING_INELIGIBLE);
                    });
                // enter you logic when the fetch is successful
                goToNextPage(props, CONSTANTS.PAGE_LOADING_INELIGIBLE);
            })
            .catch((error) => {
                // enter your logic for when there is an error (ex. error toast)
                // console.log(error);
                // goToErrorPage(props);
                goToNextPage(props, CONSTANTS.PAGE_LOADING_INELIGIBLE);
            });
    }

    useEffect(() => {
        dayjs.extend(customParseFormat);

        createPDF();
    }, []); // <-- Have to pass in [] here!

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Loading</span>
            </h2>
            <p className="mt-4 text-lg leading-6 text-white">We are processing your information.</p>
            <img className="animate-spin mt-8 h-10 w-10 text-blue-500" src={Spinner}></img>
        </>
    );
}

export default LoadingIneligible;
