import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { useState } from "react";
import classNames from "../Util/classNames";
import format from "../Util/masker";
import { goToNextPage } from "../navigator";
import CONSTANTS from "../constants";

function Healthcard(props) {
    const [hcNumber, setHcNumber] = useState(format("XXXX-XXX-XXX", props.data.hc));
    const [versionCode, setVersionCode] = useState(props.data.versionCode);
    const [hcError, setHcError] = useState(false);
    const [versionCodeError, setVersionCodeError] = useState(false);

    const hcNumberChangeHandler = (value) => {
        setHcError(false);
        const strippedValue = value.replaceAll("-", "");
        if (strippedValue.length <= 10 && !isNaN(+strippedValue)) {
            setHcNumber(format("XXXX-XXX-XXX", strippedValue));
        }
    };

    const versionCodeChangeHandler = (value) => {
        setVersionCodeError(false);
        if (value.length <= 2 && !/[^a-zA-Z]/.test(value)) {
            setVersionCode(value.toUpperCase());
        }
    };

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Enter the health card number below</span>
            </h2>
            <p className="my-4 text-lg leading-6 text-white">
                The health card number consists of ten numbers and two letters. Please enter the information for the person who is
                being tested.
            </p>

            <div className="grid grid-cols-5 max-w-lg">
                <div className="mr-4 col-span-3">
                    <label htmlFor="number" className="block text-sm font-medium text-white">
                        Number
                    </label>
                    <div className="mt-1 relative">
                        <input
                            type="text"
                            name="number"
                            id="number"
                            value={hcNumber}
                            onChange={(e) => {
                                hcNumberChangeHandler(e.target.value);
                            }}
                            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="1234-567-890"
                        />

                        <div
                            className={classNames(
                                !hcError && "invisible",
                                "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                            )}
                        >
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                        </div>
                    </div>
                    <p className={classNames(!hcError && "invisible", "mt-1 text-sm text-red-500")}>Enter a valid number</p>
                </div>
                <div className="col-span-2">
                    <label htmlFor="email" className="block text-sm font-medium text-white">
                        Version
                    </label>
                    <div className="mt-1 relative">
                        <input
                            type="text"
                            name="email"
                            id="email"
                            maxLength={2}
                            value={versionCode}
                            onChange={(e) => {
                                versionCodeChangeHandler(e.target.value);
                            }}
                            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="XX"
                        />

                        <div
                            className={classNames(
                                !versionCodeError && "invisible",
                                "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                            )}
                        >
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                        </div>
                    </div>
                    <p className={classNames(!versionCodeError && "invisible", "mt-1 text-sm text-red-500")}>Required</p>
                </div>
            </div>

            <div className="flex justify-end sm:justify-start">
                <button
                    onClick={() => {
                        let isValid = true;
                        const hcStripped = hcNumber.replaceAll("-", "");

                        if (!(hcStripped.length === 10 && !isNaN(+hcStripped))) {
                            isValid = false;
                            setHcError(true);
                        }

                        if (!(versionCode.length === 2 && !/[^a-zA-Z]/.test(versionCode))) {
                            isValid = false;
                            setVersionCodeError(true);
                        }

                        if (isValid) {
                            props.setters.setHc(hcNumber.replaceAll("-", ""));
                            props.setters.setVersionCode(versionCode);

                            goToNextPage(props, CONSTANTS.PAGE_HEALTHCARD);
                        }
                    }}
                    className="mt-8 bg-white white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-800 hover:bg-gray-200"
                >
                    Next
                </button>
            </div>
        </>
    );
}

export default Healthcard;
